import { render, staticRenderFns } from "./blockScoreList.vue?vue&type=template&id=ca3f0e44&scoped=true"
import script from "./blockScoreList.vue?vue&type=script&lang=js"
export * from "./blockScoreList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca3f0e44",
  null
  
)

export default component.exports