<!--
 * ======================================
 * 说明： 查看纪实
 * 作者： Silence
 * 文件： documentary.vue
 * 日期： 2023/9/3 23:33
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div style="height: 600px; overflow: hidden; overflow-y: auto">
    <span v-if="listData.length === 0">暂无数据</span>
    <el-card class="documentary-box-card mb-2" v-for="(item,index) in listData" :key="index">
      <el-form inline :show-message="false" class="border-b">
        <el-form-item label="班级：">
          <div class="w-20">{{ item.classes.class_name }}</div>
        </el-form-item>
        <el-form-item label="姓名：">
          <div class="w-20">{{ studentName }}</div>
        </el-form-item>
        <el-form-item class="float-right text-gray-400">
            {{ item.created_time }}
        </el-form-item>
      </el-form>
      <div class="py-4">
        <div class="pb-4">{{ item.content }}</div>
        <div v-if="item.photos">
            <el-image
              v-for="(url,key) in item.photos"
              class="p-1"
              :key="key"
              :src="url"
              :preview-src-list="item.photos">
            </el-image>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value: {
        type: Object
      }
    },
    data() {
      return {
        studentName:'',
        listData:[],
      }
    },
    computed: {},
    created() {},
    mounted() {
      this.handleLoadDocumentary()
    },
    methods: {
      handleLoadDocumentary(){
        this.$cloud.get('record/list', {
          ...this.value,
          page:1,
          size:99999,
        }).then(res => {
          if(res){
            this.listData = res?.children;
            this.studentName = res[0]?.children?.student_list.find(item=>item.id === this.value.studentId)?.real_name || ''
          }else{
            this.listData = []
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.documentary-box-card{
  ::v-deep .el-form-item{
    margin-bottom: 0;
  }
}
</style>
