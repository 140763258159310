<template>
  <div>
    <div class="flex px-4 py-6" v-if="isIE && mode !== 'view'">

      <el-button type="success" @click="handleExampleCode">导出评分模板</el-button>
      <el-upload multiple action="fileUrl" accept=".xls,.xlsx,csv" :file-list="fileList"
                 :before-upload="beforeUpload" :on-exceed="onExceed" :on-success="onSuccess"
                 :show-file-list="showFileList"
                 :http-request="uploadFile">
        <el-button type="primary" class="ml-1" icon="el-icon-upload2">导入评分数据</el-button>
      </el-upload>
      <el-button :disabled="!isChange" class="ml-1" type="warning" icon="el-icon-magic-stick" @click="handleSetScore">
        一键设置分数
      </el-button>
    </div>
    <ux-grid
        ref="plxTable"
        :max-height="height"
        show-overflow
        @table-body-scroll="scroll"
        show-summary
        @selection-change="changeTable"
        show-header-overflow="ellipsis">
      <ux-table-column type="checkbox" align="center" width="50"></ux-table-column>
      <ux-table-column v-if="isId" align="center" field="id" title="标识符" fixed :min-width="80"/>
      <ux-table-column align="center" field="real_name" title="姓名" :min-width="80"/>
      <ux-table-column align="center" field="number" title="学号" :min-width="120"/>
      <ux-table-column v-if="isSex" align="center" field="sex" title="性别" :min-width="80">
        <template slot-scope="scope">
          {{ scope.row.sex ? '男' : '女' }}
        </template>
      </ux-table-column>
      <tableColumn v-for="(item, index) in headers" :col="item" :key="index" :prop="item.key"
                   :label="item.label"/>

      <ux-table-column
        v-if="mode !== 'view'"
          fixed="right"
          title="操作"
          width="150">
        <template slot-scope="scope">
          <!--          <el-button type="text" size="small" @click="handleClick(scope)">查看</el-button>-->
          <el-button type="text" size="small" @click="handleEdit(scope)">编辑</el-button>
          <el-button type="text" size="small" @click="handleViewDocumentary(scope.row)">纪实</el-button>
        </template>
      </ux-table-column>
    </ux-grid>
  </div>
</template>
<script>
import Documentary from "../documentary.vue";
import tableColumn from "./tableColumn";
import batchOtherScoreEdit from "@/pages/web/components/batchOtherScoreEdit.vue";

function flatten(data) {
  let result = {};

  function flattenHelper(obj, parentKey) {
    let key = parentKey ? parentKey + '.' + obj.label : obj.label;
    if (obj.children) {
      obj.children.forEach(function (child) {
        flattenHelper(child, key);
      });
    } else {
      key = key + `(${obj.key})`;
      result[key] = obj.key;
    }
  }

  data.forEach(function (obj) {
    flattenHelper(obj, '');
  });

  return result;
}

import * as XLSX from 'xlsx';
import {toStyleXlsx, transformDataToSheetCells} from "./example-code";
import {getSheetCells, getSheetHeaderAndData} from "./import-code";
import batScore from "@/pages/web/components/batchBlockScoreEdit.vue";


export default {
  name: "index",
  components: {
    tableColumn
  },
  inject: {
    isId: {default: true},
    isSex: {default: true},
  },
  props: {
    mode: {
      type: String,
      default: ''
    },
    isIE: {
      type: [Number, Boolean],
      default: 1
    },
    headers: {
      type: [Array, Object],
      isIE: {
        type: [Number, Boolean],
        default: 1
      },
    },
    sheetInfo: {
      type: [Array, Object],
      default() {
        return {
          semesterId: "",//学期ID
          blockId: 4,//模块ID
          grade: '',//年级ID
          taskId: 0,//任务ID
          classId: '',//班级ID
          subjectId: 3,//任课ID
        }
      }
    },
    fileName: {
      type: String,
      default: '默认导出的文件'
    }
  },
  data() {
    return {
      isChange: false,
      height: 800,
      dataList: [],
      fileUrl: '', //上传文件的域名地址
      limitNum: 30000, //文件上传个数限制
      fileList: [], //文件列表
      showFileList: false //文件列表是否显示,默认不显示
    };
  },
  methods: {
    changeTable() {
      console.log("选择列")
      const list = this.$refs.plxTable.getCheckboxRecords()
      this.isChange = list.length > 0
    },
    // 查看纪实
    handleViewDocumentary(row) {
      this.$cloud.dialog({
        title: '查看纪实',
        data: {
          studentId: row.id,
          semesterId: this.sheetInfo.semesterId,
        },
        component: Documentary,
        customClass: 'documentary-custom-class'
      })
    },

    scroll({scrollTop, scrollLeft}) {
      this.scrollTop = scrollTop
    },
    getHeader() {
      return flatten(this.headers);
    },
    handleClick(scope) {
      console.log("查看数据结构", scope)
    },
    handleMapHeaders(type = 1) {
      let mapObj = this.getHeader();
      let mapConfig = {
        "标识符": 'id',
        "姓名": 'real_name',
        "学号": "number",
        ...mapObj,
      }
      if (type === 1) {
        return mapConfig;
      } else {
        let mapArray = [];
        for (const key in mapConfig) {
          let item = mapConfig[key];
          let obj = {}
          obj[key] = item;
          mapArray.push(obj)
        }
        return mapArray;
      }

    },
    reloadData(data) {
      this.dataList = data;
      this.$refs.plxTable.reloadData(data)
    },
    // 文件上传类型
    // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet --- 后缀为 .xlsx
    // application/vnd.ms-excel --- 后缀为 .xls或.csv
    //文件上传之前的钩子,可以做一些验证或限制
    beforeUpload(file) {
      console.log('beforeUpload', file);
      let regExp = file.name.replace(/.+\./, '');
      let lower = regExp.toLowerCase(); //把大写字符串全部转为小写字符串
      let suffix = ['xls', 'xlsx'];
      if (suffix.indexOf(lower) === -1) {
        return this.$message.warning('请上传后缀名为 xls、xlsx 的附件 !');
      }
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return this.$message.error('请上传文件大小不能超过 2MB 的附件 !');
      }
    },
    //文件超出个数限制时的钩子
    onExceed(files, fileList) {
      return this.$message.warning(`只能选择${this.limitNum}个文件,当前共选择了${files.length + fileList.length}个`)
    },
    //文件上传成功时的钩子
    onSuccess(response, file, fileList) {
      console.log('onSuccess', file);
    },
    //覆盖默认的上传行为,可以自定义上传的实现
    uploadFile(event) {
      console.log("event", event)
      let file = event.file;
      const reader = new FileReader()
      reader.onload = e => {
        const sheets = []
        const data = e.target && e.target.result
        const workbook = XLSX.read(data, {type: 'array'})
        let sheetInfo = '';
        for (const sheetName of workbook.SheetNames) {
          console.log("查看名称,sheetName", sheetName)
          if (!sheetInfo) {
            let arr = (sheetName.replace('world', 'javascript')).split('-');
            sheetInfo = {
              semesterId: arr[1] ?? 0,//学期ID
              blockId: arr[2] ?? 0,//模块ID
              grade: arr[3] ?? 0,//年级ID
              classId: arr[4] ?? 0,//班级ID
              subjectId: arr[5] ?? 0,//任课ID
            }
          }
          const worksheet = workbook.Sheets[sheetName]
          sheets.push(getSheetCells(worksheet))
        }
        console.log('所有 sheets 单元格数据', sheets)
        try {
          const textKeyMap = this.handleMapHeaders();
          let array = sheets[0].map(item=>{
            return item.slice(0,Object.keys(textKeyMap).length)
          })
          const {dataList} = getSheetHeaderAndData(array, textKeyMap);
          this.$message.info("导入大概需要1分钟左右，请等待")
          this.$cloud.post("task/student/import", {
            dataList, sheetInfo
          }).then(data => {
            console.log("获取导入结果")
          }).catch(error => {

          })

          console.log('dataList', dataList, sheetInfo) // 传递给后端的数据

        } catch (error) {
          console.log('error：',error)
          alert('解析出错，请检查 textKeyMap 是否为正确的 JSON 数据')
          alert(error)
        }
      }
      reader.readAsArrayBuffer(file)
    },
    handleExampleCode() {
      const cells = []// 转化为 Excel 后的所有单元格数据
      let merges = {}// 转化为 Excel 后的表头合并单元格配置


      let data = this.dataList;
      let textKeyMaps = this.handleMapHeaders(2);

      // 调用函数，将数据源转化为 Excel 单元格数据，及 Excel 表头合并单元格配置
      const {headerMerges, cells: _cells} = transformDataToSheetCells(data, textKeyMaps)
      merges.value = headerMerges
      cells.value = _cells

      const worksheet = XLSX.utils.aoa_to_sheet(_cells)
      worksheet['!merges'] = headerMerges

      // 所有单元格居中显示
      Object.values(worksheet).forEach(cell => {
        if (cell.v) {
          cell.s = {
            alignment: {
              horizontal: 'center',
              vertical: 'center',
            },
          }
        }
      })
      let fileName = this.fileName + `-${this.sheetInfo.semesterId}-${this.sheetInfo.blockId}-${this.sheetInfo.grade}-${this.sheetInfo.classId}`;
      toStyleXlsx({
        filename: `${fileName}`,
        worksheet
      })
    },
    createHeader() {
      let keyList = this.getHeader()
      console.log('keyList', this.headers)
      let codeList = []
      for (const keyListKey in keyList) {
        let item = {
          label: keyListKey,
          value: keyList[keyListKey]
        }
        codeList.push(item)
      }
      let headers = this.headers
      let headerList = []

      function createHeader(headers) {
        headers.forEach(x => {
          if (x.children) {
            createHeader(x.children)
          } else {
            headerList.push(x)
          }
        })
      }

      createHeader(headers)
      codeList.forEach(v => {
        let x = headerList.find(z => z.key === v.value)
        if (x) {
          v['type'] = x['type']
        }
      })
      return codeList
    },
    handleSetScore() {
      let codeList = this.createHeader()
      const list = this.$refs.plxTable.getCheckboxRecords().map(v => v.id)
      if (list.length === 0) {
        this.$message.warning("请选择学生")
        return
      }
      this.$cloud.dialog({
        title: '请进行评分',
        data: {
          semesterId: this.sheetInfo.semesterId,
          grade: this.sheetInfo.grade,
          classId: this.sheetInfo.classId,
          blockId: this.sheetInfo.blockId,
          taskId: this.sheetInfo.taskId,
          studentList: list,
          codeList: codeList
        },
        component: batchOtherScoreEdit,
        success: '保存成功!',
        warning: '保存失败!',
        refresh: this.handleRefresh
      })

    },
    handleRefresh() {
      this.$emit('refresh')
    },
    handleEdit(row) {
      console.log('编辑', row.row.id, row)
      let codeList = this.createHeader()
      console.log("批量设置", codeList)
      const list = this.$refs.plxTable.getCheckboxRecords().map(v => v.id)
      console.log('list', list)
      this.$cloud.dialog({
        title: '编辑评分',
        data: {
          semesterId: this.sheetInfo.semesterId,
          grade: this.sheetInfo.grade,
          classId: this.sheetInfo.classId,
          blockId: this.sheetInfo.blockId,
          taskId: this.sheetInfo.taskId,
          student: row.row.id,
          data: row.row,
          codeList: codeList
        },
        component: batchOtherScoreEdit,
        success: '保存成功!',
        warning: '保存失败!',
        refresh: this.handleRefresh
      })
    }
  }
};
</script>
<style lang="scss">
.documentary-custom-class {
  width: 780px;
}
</style>
