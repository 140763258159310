<template>
  <div>
    <el-cascader
        :value="value"
        @change="handleChange"
        :options="options"
        :props="{value:'id',label:'name'}"
        :show-all-levels="false">
    </el-cascader>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [Array],
      default: []
    },
    require: {
      type: Boolean,
      default: true
    },
    semesterId: {
      type: [String, Number],
      default: ''
    }
  },
  watch: {
    semesterId() {
      this.handleLoadOptions()
    }
  },
  /**
   * 接收父级传递的数据
   */
  inject: ["WEB"],
  data() {
    return {
      options: [],
    };
  },
  methods: {
    async handleLoadOptions() {
      this.options = []
      let menuInfo = this.WEB.getMenuInfo();
      console.log("menuInfo--", menuInfo)
      await this.$cloud.post('/classes/list/tree', {
        semesterId: this.semesterId,
        menuId: menuInfo.id,
        blockId: menuInfo.block_id
      }).then(res => {
        this.options = res
        if (this.options.length) {
          if (this.options[0].children.length) {
            let value = [this.options[0].id, this.options[0].children[0].id]
            console.log("设置默认值", value)
            this.handleChange(value)
          } else {
            this.$message.error('请先设置班级信息')
          }
        } else {
          this.$message.error("请先设置年级信息")
        }
      })
    },
    handleChange(e) {
      console.log('选择班级', e)
      this.$emit('input', e)
      this.$emit('change', e)
    }
  }
};
</script>
<style scoped>

</style>
