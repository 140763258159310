<template>
  <el-form ref="form" :model="form" :rules="rules" class="mt-2">
    <el-form-item label="评分：" prop="score" label-width="80px">
      <el-radio-group v-model="form.score">
        <el-radio v-for="item in options" :key="item.id" :label="item.id">{{item.title}}</el-radio>
      </el-radio-group>
    </el-form-item>
  </el-form>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        form:{
          score:'',
        },
        options: [
          {
            id: 'A',
            title: 'A'
          },
          {
            id: 'B',
            title: 'B'
          },
          {
            id: 'C',
            title: 'C'
          },
          {
            id: 'D',
            title: 'D'
          }
        ],
        rules: {
          score: [
            { required: true, message: '请进行评分', trigger: 'change' }
          ]
        },
      }
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
      handleSubmit() {
        return new Promise( ( resolve , reject ) => {
          this.$refs['form'].validate( ( valid ) => {
            if ( valid ) {
              this.$cloud.post( 'evaluate/data/edit/bat' , {
                ...this.value,
                score: this.form.score
              }).then( ( res ) => {
                resolve( true )
              } ).catch( () => {
                reject( false )
              } )
            } else {
              reject( false )
            }
          } )
        } )
      }
    },
  }
</script>
<style lang="scss" scoped></style>
