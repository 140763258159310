<!--
 * ======================================
 * 说明： 教师端 - 学业水平
 * 作者： Silence
 * 文件： study.vue
 * 日期： 2023/8/3 0:32
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div>
    <batchTable :mode="mode" @refresh="handleLoadDataList" :isIE="isIE" :sheet-info="sheetInfo" :file-name="fileName" ref="tableData"
                :headers="headers"/>
  </div>
</template>
<script type="text/javascript">

function getLastLeaves(arr) {
  let result = [];

  function traverse(item) {
    if (item.children && item.children.length > 0) {
      item.children.forEach(traverse);
    } else {
      result.push(item);
    }
  }

  arr.forEach(traverse);

  return result;
}

import semesterSelect from "./semesterSelect";
import batchTable from "./batchTable";

export default {
  name: "otherGrades",
  components: {
    semesterSelect,
    batchTable
  },
  /**
   * 组建属性
   * @type {Object}
   */
  props: {
    mode:{
      type: String,
      default: ''
    },
    isIE: {
      type: [Number, Boolean],
      default: 1
    },
    fileName: {
      type: String,
      default: '默认导出的文件'
    },
    headers: {
      type: [Object, Array]
    },
    value: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      options: [],
      revealList: [],
      height: 500,
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    formSearch: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    sheetInfo() {
      return this.formSearch
    }
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  mounted() {

  },
  methods: {
    /**
     * 加载页面数据呀
     */
    handleLoadDataList() {
      //获取头部信息
      let header = this.$refs.tableData.getHeader()
      this.$cloud.post("task/student/data", this.formSearch, {
        header: header,
        headers: getLastLeaves(this.headers)
      }).then(res => {
        this.$refs.tableData.reloadData(res)
      }).catch(err => {
        if (err.response) {
          // this.$message.error(err.response.data.msg)
        }
        this.$refs.tableData.reloadData([])
      })
    },
    handleExport() {
      this.$message({
        type: 'info',
        message: '导出中，请稍后……'
      });
    }
  },
};
</script>
<style lang="scss">
.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
</style>
