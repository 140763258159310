<template>
    <el-select v-model="id" placeholder="请选择学期">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.name"
          :value="item.id"></el-option>
    </el-select>
</template>
<script>
export default {
  name: "semesterSelect",
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    require: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      options: [],
    };
  },
  computed: {
    id: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  },
  mounted() {
    this.handleLoadSemesterOptions()
  },
  methods: {
    handleLoadSemesterOptions() {
      return this.$cloud.page("semester/list", {
        size: 99999,
        page: 1,
      }).then(res => {
        this.options = res.children;
        if (res.children.length === 0) {
          this.$message.error("请先添加学期信息")
        } else {
          res.children.map(v => {
            v['name'] = v['semester_name']
            return v
          })
          if (this.require) {
            this.id = res.children[0].id;
          }
        }
        this.$emit('onLoad')
      })
    },
  }
};
</script>
<style scoped>

</style>
