<template>
  <Table :hasAction="mode !== 'view'" :autoLoading="false" :options="handleOptions" :loadData="handleLoadData" ref="table" @change="onSelectChange">
    <template #search>
      <div class="m-4">
        <el-form :inline="true" :model="formSearch" :rules="formRule" ref="ruleForm" class="demo-form-inline"
                 label-width="100px">
          <el-form-item label="选择指标" prop="studyId">
            <el-cascader
                ref="cascader"
                :props="{value:'key',label:'label'}"
                v-model="formSearch.studyId"
                :options="options"
                @change="changeStudyId"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="阶段" prop="stage">
            <el-select v-model="formSearch.stage" placeholder="阶段">
              <el-option :label="`第${item.label}阶段`" :value="item.label" v-for="item in stage" :key="item.label">
                <span style="float: left">{{ `第${item.label}阶段` }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.has ? "已导入" : "" }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学生姓名">
            <el-input v-model="formSearch.realName" placeholder="学生姓名"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button :disabled="deleteDisabled" type="danger" @click="onDelete">删除</el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #action v-if="mode !== 'view'">
      <div>
        <el-button type="success" @click="handleExport">导出评分模板</el-button>
        <el-upload
            action=""
            :show-file-list="false"
            style="display: contents"
            accept=".xls,.XLS,.xlsx,.XLSX"
            :http-request="handleImport"
        >
          <el-button icon="el-icon-upload2" type="primary" class="ml-1"
          >导入评分数据
          </el-button>
        </el-upload>
        <el-button :disabled="!isChange" class="ml-1" icon="el-icon-magic-stick" type="warning" @click="handleSetScore">
          一键设置分数
        </el-button>
        <el-button icon="el-icon-download" class="ml-1" @click="handleDownloadAverageScore">导出平均分</el-button>
      </div>
    </template>
    <template v-if="mode !== 'view'" #handle="{index,row}">
      <el-button type="text" icon="el-icon-edit" @click="handleScore(row)">评分</el-button>
    </template>
  </Table>
</template>

<script>
// 学业水平指标分数列表
import semesterSelect from "@/pages/web/components/semesterSelect";
import classSelect from "@/pages/web/components/classSelect.vue";
import score from "./blockScoreEdit.vue"
import batScore from "./batchBlockScoreEdit.vue"
import mixin from "@/mixin/execlExportImport";

export default {
  name: "blockScoreList",
  components: {
    semesterSelect,
    classSelect
  },
  mixins: [mixin],
  props: {
    mode:{
      type: String,
      default: ''
    },
    semesterId: {
      type: [String, Number],
      default: ''
    },
    grade: {
      type: [String, Number],
      default: ''
    },
    taskId: {
      type: [String, Number],
      default: ''
    },
    classId: {
      type: [String, Number],
      default: ''
    },
    blockId: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isChange: false,
      stage: [],
      //搜索表单结构
      formSearch: {
        studyId: [],
        stage: '',
        realName: ''
      },
      formRule: {
        semesterId: [
          {required: true, message: '请选择学期', trigger: 'change'}
        ],
        classData: [
          {required: true, message: '请选择班级', trigger: 'change'}
        ],
        studyId: [
          {required: true, message: '请选择指标', trigger: 'change'}
        ],
        stage: [
          {required: true, message: '请选择阶段', trigger: 'change'}
        ],
      }
    }
  },
  computed: {
    deleteDisabled() {
      return !(this.semesterId && this.grade && this.classId && this.blockId && this.formSearch.studyId && this.formSearch.studyId.length > 0 && this.formSearch.stage)
    }
  },
  methods: {
    onSelectChange(rows) {
      console.log("选择了", rows.length, rows)
      if (rows.length > 0) {
        this.isChange = true
      } else {
        this.isChange = false;
      }
    },

    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/web/study").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        console.log('报错', err)
        table.errMsg(err)
      })
    },
    handleLoadData(table) {
      console.log('加载数据', table)
      this.$cloud.get("evaluate/data/list", {
        semesterId: this.semesterId,
        grade: this.grade,
        classId: this.classId,
        blockId: this.blockId,
        taskId: this.taskId,
        studyId: this.formSearch.studyId,
        stage: this.formSearch.stage,
        realName: this.formSearch.realName,
      }).then(res => {
        console.log('res', res)
        const data = {
          children: res,
          count: 1,
          total: res.length
        }
        table.setData(data);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    onSubmit() {
      console.log("查询", this.formSearch)
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.handleRefresh();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 删除导入
    onDelete(){
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("evaluate/data/delete", {
          semesterId: this.semesterId,
          grade: this.grade,
          classId: this.classId,
          blockId: this.blockId,
          studyId: this.formSearch.studyId,
          stage: this.formSearch.stage,
        }).then(res => {
          this.$message.success('删除成功');
          this.handleRefresh();
        }).catch(err => {
          this.$message.error(err);
        })
      })
    },
    
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    },
    changeStudyId(e) {
      console.log('选择了指标', e)
      this.stage = []
      this.formSearch.stage = ''
      // 获取阶段
      let url = this.mode !== 'view' ? 'task/stage' : 'task/lesson/stage'
      this.$cloud.post(url, {
        semesterId: this.semesterId,
        grade: this.grade,
        blockId: this.blockId,
        taskId: this.taskId,
        studyId: this.formSearch.studyId,
        classId: this.classId
      }).then(res => {
        this.stage = res
        console.log("获取阶段", this.stage)
      })
    },

    // 导出评分模板
    handleExport() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$cloud.get("evaluate/data/list", {
            semesterId: this.semesterId,
            grade: this.grade,
            classId: this.classId,
            blockId: this.blockId,
            taskId: this.taskId,
            studyId: this.formSearch.studyId,
            stage: this.formSearch.stage,
            realName: this.formSearch.realName,
          }).then(res => {
            if (res?.length) {
              let tHeader = ["学号", "姓名", "性别", "指标", '阶段', "评分（分值：A|B|C|D）"];
              let fields = ["number", "real_name", "sex", "target", "stage", "score"];
              let formatJson = (fliterVal, tableData) => {
                return tableData.map((v) => fliterVal.map((j) => {
                  if (j === "target") {
                    return this.$refs.cascader?._data?.presentText || '-'
                  } else if (j === "stage") {
                    return `第${this.formSearch.stage}阶段`
                  }
                  return v[j]
                }));
              };
              let excelData = formatJson(fields, res);
              this.exportElxs({
                header: tHeader,
                data: excelData, //数据
                filename: `学业水平评分模板`,
                autoWidth: true,
                bookType: "xlsx",
              });
            } else {
              this.$message.error('未查询到评分模板！')
            }
          })
        } else {
          return false;
        }
      });
    },
    // 批量导入评分
    handleImport(e) {
      this.httpRequest(e, (data) => {
        if (data && data.length) {
          let params = {
            semesterId: this.semesterId,
            grade: this.grade,
            classId: this.classId,
            blockId: this.blockId,
            taskId: this.taskId,
            code: this.formSearch.studyId[this.formSearch.studyId.length - 1],
            stage: this.formSearch.stage,
            scoreList: data.map(item => {
              return {
                number: item['学号'],
                score: item['评分（分值：A|B|C|D）'],
              }
            })
          }

          this.$cloud.post('evaluate/data/import', params).then(res => {
            this.$message.success('导入成功!')
            this.handleRefresh()
          })
        }
      })
    },

    handleScore(row) {
      this.$cloud.dialog({
        title: '请进行评分',
        data: {
          semesterId: this.semesterId,
          grade: this.grade,
          classId: this.classId,
          studentId: row.id,
          blockId: this.blockId,
          taskId: this.taskId,
          code: this.formSearch.studyId[this.formSearch.studyId.length - 1],
          stage: this.formSearch.stage,
        },
        component: score,
        success: '保存成功!',
        warning: '保存失败!',
        refresh: this.handleRefresh
      })
    },
    handleSetScore() {
      console.log("批量评分")
      const list = this.$refs.table.getCheckboxRecords().map(v => v.id)
      console.log('list', list)
      this.$cloud.dialog({
        title: '请进行评分',
        data: {
          semesterId: this.semesterId,
          grade: this.grade,
          classId: this.classId,
          studentList: list,
          blockId: this.blockId,
          taskId: this.taskId,
          code: this.formSearch.studyId[this.formSearch.studyId.length - 1],
          stage: this.formSearch.stage,
        },
        component: batScore,
        success: '保存成功!',
        warning: '保存失败!',
        refresh: this.handleRefresh
      })
    },
    
    // 导出平均分
    handleDownloadAverageScore(){
      if(!this.formSearch?.studyId?.length){
        this.$message.error('请先选择指标再点击导出平均分！')
        return false;
      }

      this.$cloud.get('/evaluate/avg/list',{
        semesterId: this.semesterId,
        classId: this.classId,
        blockId: this.blockId,
        studyId: this.formSearch.studyId,
      }).then(res=>{
        if(res?.length){
          let tHeader = ["学号", "姓名", "平均分"];
          let fields = ["number", "real_name", "avg"];
          let formatJson = (fliterVal, tableData) => {
            tableData.map((v) => fliterVal.map((j) => v[j]));
            return tableData.map((v) => fliterVal.map((j) => v[j]));
          };
          let excelData = formatJson(fields, res);
          this.exportElxs({
            header: tHeader,
            data: excelData, //数据
            filename: `学业水平平均分`,
            autoWidth: true,
            bookType: "xlsx",
          });
        }else{
          this.$message.error('未查询到平均分！')
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
