export default {
    render(h) {
        return this.render(h, this.col)
    },
    props: {
        col: {}
    },
    data: function () {
        return {}
    },
    methods: {
        render(h, header) {
            let column = [];
            // 如果表头还有子表头
            if (header.children) {
                let children = [];
                for (let i = 0; i < header.children.length; i++) {
                    // 递归渲染
                    children.push(this.render(h, header.children[i]))
                }
                column.push(h('ux-table-column', {
                    props: {
                        title: header.label,
                        align: 'center',
                    }
                }, children))
            } else {
                let label = header.label
                if (header.subject_id) {
                    label = header.label + `(${header.key})`
                }
                column.push(h('ux-table-column', {
                    props: {
                        // 可以自己追加其他el-table-colum支持的参数
                        title: label,
                        field: header.key,
                        width: header.width ?? 140,
                        resizable: true,
                        align: 'center',
                    },
                    scopedSlots: {
                        default: scope => {
                            let value;
                            // 有exeFun优先执行
                            if (header.exeFun) {
                                value = header.exeFun(scope.row)
                            } else {
                                value = scope.row[header.key]
                            }
                            if (header.type) {
                                switch (header.type) {
                                    case 1:
                                        // console.log("我是打星的", {header, value})
                                        return h('el-rate', {
                                            props: {
                                                value: parseInt(value ?? 0),
                                                disabled: true,
                                                max: 3
                                            },
                                        })
                                    case 2:
                                        // case 2的代码块
                                        return h('span', value)
                                    case 3:
                                        let arr = ['--', 'D', 'C', 'B', 'A'];
                                        let num = 0;
                                        if (parseInt(value) >= 3.5) {
                                            num = 4;
                                        }
                                        if (parseInt(value) <= 3.5 && parseInt(value) >= 2.5) {
                                            num = 3;
                                        }
                                        if (parseInt(value) <= 2.5 && parseInt(value) >= 1.5) {
                                            num = 2;
                                        }
                                        if (parseInt(value) <= 1.5) {
                                            num = 1;
                                        }
                                        value = arr[num] ?? '--'
                                        return h('span', value)
                                    case 4:
                                        return h('span', value)
                                    default:
                                        return h('span', value)
                                }
                            }

                        }
                    }
                }))
            }
            return column
        },
    }
}
