<template>
  <el-form ref="form" :model="form" :rules="rules" class="mt-2">
    <el-form-item label="得分项：" prop="code" label-width="80px">
      <el-select v-model="form.code" @change="handleChange">
        <el-option v-for="item in value.codeList" :key="item.value" :value="item.value" :label="item.label"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="评分：" prop="score" label-width="80px" v-if="form.code">
<!--      {-->
<!--      label: '打星',-->
<!--      value: 1-->
<!--      }, {-->
<!--      label: '打分',-->
<!--      value: 2-->
<!--      }, {-->
<!--      label: '选级',-->
<!--      value: 3-->
<!--      }, {-->
<!--      label: '填值',-->
<!--      value: 4-->
<!--      },-->
<!--      打星-->
      <el-rate style="margin-top: 7px" :max="3" v-model="form.score" v-if="scoreType === 1"></el-rate>
<!--      打分-->
      <el-input-number  v-model="form.score"  v-if="scoreType === 2"/>
<!--      填值-->
      <el-input :step="1" v-model="form.score"  v-if="scoreType === 4"/>
<!--      选级-->
      <el-radio-group v-model="form.score"  v-if="scoreType === 3">
        <el-radio v-for="item in options" :key="item.id" :label="item.id">{{item.title}}</el-radio>
      </el-radio-group>
    </el-form-item>
  </el-form>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        form:{
          code:'',
          score:null,
        },
        options: [
          {
            id: 4,
            title: 'A'
          },
          {
            id: 3,
            title: 'B'
          },
          {
            id: 2,
            title: 'C'
          },
          {
            id: 1,
            title: 'D'
          }
        ],
        rules: {
          code: [
            { required: true, message: '请选择得分', trigger: 'change' }
          ],
          score: [
            { required: true, message: '请进行评分', trigger: 'change' }
          ]
        },
      }
    },
    computed:{
      scoreType() {
        let item = this.value.codeList.find(x=>x.value === this.form.code)
        if(item){
          if(item.type === 5 || item.type === 6){
            this.$message.error("该指标不支持一键设置")
           return ''
          }else{
            return item.type
          }
        }else{
          return ''
        }
      }
    },
    methods: {
      handleChange(e) {
        console.log("选择",e,this.value)
        if(this.value.student){
          console.log("是编辑")
          if(this.scoreType === 1){
            this.form.score = this.value.data[e]?parseInt(this.value.data[e]):null
          }else{
            this.form.score = this.value.data[e]
          }
        }
      },
      handleSubmit() {
        console.log("提交",this.form,this.value)
        return new Promise( ( resolve , reject ) => {
          this.$refs['form'].validate( ( valid ) => {
            if ( valid ) {
              if(this.value.studentList){
                this.$cloud.post( 'evaluate/artistic/data/bat' , {
                  ...this.value,
                  code: this.form.code,
                  score: this.form.score
                }).then( ( res ) => {
                  resolve( true )
                } ).catch( () => {
                  reject( false )
                } )
              }
              if(this.value.student){
                this.$cloud.post( 'evaluate/artistic/data/edit' , {
                  ...this.value,
                  code: this.form.code,
                  score: this.form.score
                }).then( ( res ) => {
                  resolve( true )
                } ).catch( () => {
                  reject( false )
                } )
              }

            } else {
              reject( false )
            }
          } )
        } )
      }
    },
  }
</script>
<style lang="scss" scoped></style>
